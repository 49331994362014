@use 'styles/breakpoints' as *;

.footer {
  font-family: var(--inter-font);
  background-color: var(--clr-dark-gray);
  color: var(--clr-white);
  padding: calc(4rem + var(--slope-height)) 0 4rem;
  margin-top: calc(-1 * var(--slope-height));
  transform: translateZ(0);

  &.up {
    clip-path: polygon(0 var(--slope-height), 100% 0%, 100% 100%, 0 100%);
  }

  &.down {
    clip-path: polygon(0 0, 100% var(--slope-height), 100% 100%, 0 100%);
  }
}

.footerContent {
  display: grid;
  justify-content: center;
  row-gap: 4rem;
  grid-template-areas: 'logo' 'menu' 'socials' 'address' 'privacy';

  @include desktop {
    grid-template-areas:
      'logo menu address'
      'privacy privacy socials';
    row-gap: 8rem;
    column-gap: 0;
    justify-content: space-between;
    grid-template-columns: auto max-content auto;
  }
}

.jsLove {
  display: grid;
  justify-items: center;
  gap: 1rem;
  grid-area: logo;
  align-self: start;
}

.heart {
  color: #e93232;
}

.menuWrapper {
  grid-area: menu;
}

.menu {
  column-count: 2;
  column-gap: 5rem;
  margin-top: calc(-1 * var(--btn-padding-y));
}

.socials {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  grid-area: socials;

  @include desktop {
    justify-self: end;
  }
}

.addressWrapper {
  display: grid;
  gap: 1.5rem;
  grid-area: address;
  align-self: start;
}

.address {
  font-weight: 500;
  font-size: var(--p-reg-size);
  font-style: normal;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop {
    align-items: flex-start;
  }
}

.privacy {
  grid-area: privacy;
  display: grid;
  gap: 0.5rem;
  justify-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  font-size: var(--p-reg-size);
  line-height: 1.8;
  text-transform: lowercase;

  @include desktop {
    grid-template-columns: repeat(2, auto);
    gap: 2.5rem;
    justify-self: start;
  }
}

.languageSelector {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: -2rem;
  align-items: center;

  @include desktop {
    grid-column: 1/ 4;
    margin-top: -5rem;
  }
}

.languageButton {
  font-size: 2rem;
  opacity: 0.5;
  transition: opacity var(--transition-duration) ease;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    opacity: 1;
  }
}
