@tailwind base;
@tailwind components;
@tailwind utilities;

@import './utils';
@import './breakpoints';

@layer components {
  :root {
    font-size: fluid(16, 20, 320, 1280);

    @include desktop {
      font-size: fluid(calc((16 / 24) * 16), 16, 1280, 2560);
    }

    --clr-primary: #13ca91;
    --clr-white: #ffffff;
    --clr-black: #000000;
    --clr-light-gray: #f8f8f8;
    --clr-dark-gray: #1e1e1e;
    --clr-dark-gray-muted: #8d8d8d;
    --clr-dark-gray-gray-muted: #a5a5a5;
    --clr-dark-gray-faded: rgba(30, 30, 30, 0.15);
    --clr-react-blue: #3178c6;
    --clr-typescript-blue: #61dafb;
    --clr-vue-green: #42b883;
    --clr-angular-red: #dd0031;
    --clr-node-js-green: #8cc84b;
    --clr-neutral-default-base: #2f2f2f;
    --clr-neutral-subtle: rgba(30, 30, 30, 0.07);
    --clr-neutral-subtle-alpha: rgba(30, 30, 30, 0.7);
    --clr-error: #e72424;
    --clr-error-subtle: #eb4747;
    --clr-error-very-subtle: #f9c8c8;
    --clr-error-very-very-subtle: #fce8e8;

    --transition-duration: 0.2s;

    --btn-morph-size-default: 0%;
    --btn-morph-size-enlarged: 15%;

    --btn-font-size: 1.125rem;
    --btn-icon-size: 2rem;
    --btn-border-size: 0.1875rem;
    --btn-padding-y: 1.25rem;
    --btn-padding-x: 1.5rem;
    --btn-gap: 1rem;

    --menu-font-size: 1.5rem;
    --menu-nav-font-size: 1.125rem;
    --menu-nav-height: 4.5rem;

    --menu-item-after-size: 0.25rem;
    --menu-item-after-spacing: 0.5rem;
    --menu-item-after-font-size: 1.25rem;
    --menu-item-after-width: 1.5rem;
    --menu-item-after-height: 1.5rem;
    --menu-item-after-margin-left: 0.5rem;

    --logo-width: 10.125rem;

    --hero-title-font-size: clamp(7rem, 20vw, 16rem);
    --hero-subject-font-size: 1rem;

    --bottom-bar-icon-size: 2rem;
    --bottom-bar-pad: 1.3125rem 0 1.3125rem 0;

    --case-hero-logo-width: 10rem;
    --case-hero-logo-height: 2rem;
    --case-hero-title-gap: 2rem;
    --case-hero-title-size: 5.5rem;
    --case-hero-img-overflow: 15rem;
    --case-hero-detail-gap: 2.25rem;
    --case-hero-detail-padding-top: 9.375rem;
    --case-hero-detail-padding-bottom: 3.75rem;
    --case-hero-detail-columns: 1;
    --case-hero-detail-column-gap: 1.5rem;
    --case-hero-detail-row-gap: 0.5rem;
    --case-hero-detail-title-size: 1.25rem;
    --case-hero-detail-data-size: 1.125rem;

    --icon-size: 2rem;
    --illustration-size: 6rem;

    --slope-height: 1.5rem;
    --slope-padding: 6.5rem;
    --slope-inner-gap: 4rem;

    --h2-reg-size: 4.75rem;
    --h2-alt-size: 2rem;
    --h2-comp-size: 4.75rem;
    --h2-sub-size: 2rem;

    --h3-reg-size: 3.5rem;
    --h3-comp-size: 1.5rem;
    --h3-sub-size: 1.125rem;

    --p-reg-size: 1rem;
    --p-large-size: 1.125rem;

    --list-item-icon-size: 2rem;
    --list-item-illustration-size: 4.5rem;
    --list-item-visual-size: 3.75rem;
    --list-item-title-size: 1.25rem;
    --list-item-subtitle-size: 1.125rem;

    --stats-gap: 3rem;
    --stats-item-gap: 1.25rem;
    --stats-percentage-size: 7.5rem;
    --stats-copy-size: 1.5rem;

    --text-block-gap: 1.5rem;

    --media-landscape-underlay-padding: 0;
    --media-landscape-gap: 2.5rem;
    --media-landscape-content-padding: 0;
    --media-logo-padding: 1.5rem;
    --media-logo-width: 7rem;

    --author-fancy-gap: 1rem;
    --author-fancy-img-size: 3.5rem;
    --author-fance-img-border: 0.25rem;

    --author-simple-gap: 1.25rem;
    --author-simple-img-size: 5rem;

    --tile-gap: 2rem;
    --tile-inner-paddding: 0;
    --tile-inner-gap: 1rem;
    --tile-image-width: 24.375rem;
    --tile-growth-side: 1.5rem;

    --input-icon-gap: 0.75rem;
    --input-border-width: 0.1875rem;
    --input-font-size: 1.125rem;
    --input-pad-left: 0.25rem;
    --input-padding: 1rem;
    --input-upload-gap: 0.5rem;
    --input-upload-padding: 2.5rem;

    --carousel-slide-spacing: 1.5rem;
    --carousel-button-spacing: 60rem;
    --carousel-controls-width: 20.5rem;
    --carousel-controls-margin: 4rem;

    --people-carousel-width: 20rem;
    --people-carousel-ratio: 2/3;
    --people-carousel-padding: 1.5rem;
    --people-carousel-v-gap: 1.25rem;
    --people-carousel-name-gap: 0.5rem;

    --pictures-carousel-height: 12.5rem;
    --testimonial-width: 20.375rem;
    --testimonial-no-logo-gap: 1.5rem;
    --testimonial-no-logo-icon-height: 5.8125rem;
    --testimonial-no-logo-quote-margin: 1rem;
    --testimonial-no-logo-font-size: 1.5rem;

    --testimonial-logo-gap: 2rem;
    --testimonial-logo-logo-height: 3rem;
    --testimonial-logo-font-size: 1.125rem;
  }

  @include desktop {
    :root {
      --btn-font-size: 1.5rem;
      --btn-icon-size: 2.5rem;
      --btn-border-size: 0.25rem;
      --btn-padding-y: 1.75rem;
      --btn-padding-x: 2rem;
      --btn-gap: 1rem;

      --menu-font-size: 3.5rem;
      --menu-nav-font-size: 1.75rem;
      --menu-nav-height: 6.25rem;

      --menu-max-height: 37.5rem;
      --menu-item-after-size: 0.5rem;
      --menu-item-after-spacing: 1.5rem;
      --menu-item-after-font-size: 2rem;
      --menu-item-after-width: 3rem;
      --menu-item-after-height: 3rem;
      --menu-item-after-margin-left: 1.25rem;

      --logo-width: 13.5625rem;

      --hero-title-font-size: clamp(22rem, 20vw, 16rem);
      --hero-subject-font-size: 2.625rem;

      --bottom-bar-icon-size: 3rem;
      --bottom-bar-pad: 2.5rem 0 2.5rem 0;

      --case-hero-logo-width: 16rem;
      --case-hero-logo-height: 5rem;
      --case-hero-title-gap: 4rem;
      --case-hero-title-size: 11rem;
      --case-hero-img-overflow: 16rem;
      --case-hero-detail-gap: 6.125rem;
      --case-hero-detail-padding-top: 3.625rem;
      --case-hero-detail-padding-bottom: var(--case-hero-detail-padding-top);
      --case-hero-detail-columns: 4;
      --case-hero-detail-column-gap: 2.5rem;
      --case-hero-detail-row-gap: 1rem;
      --case-hero-detail-title-size: 1.5rem;
      --case-hero-detail-data-size: 1.25rem;

      --btn-gap: 1.625rem;

      --icon-size: 2.5rem;
      --illustration-size: 9rem;

      --slope-height: 6rem;
      --slope-padding: 17.5rem;
      --slope-inner-gap: 8rem;

      --h2-reg-size: 9.75rem;
      --h2-alt-size: 4rem;
      --h2-comp-size: 8rem;
      --h2-sub-size: 4rem;

      --h3-reg-size: 6rem;
      --h3-comp-size: 2.5rem;
      --h3-sub-size: 1.75rem;

      --p-reg-size: 1.5rem;
      --p-large-size: 1.75rem;

      --list-item-icon-size: 3rem;
      --list-item-illustration-size: 7rem;
      --list-item-visual-size: 6rem;
      --list-item-title-size: 1.75rem;
      --list-item-subtitle-size: 1.5rem;

      --stats-gap: 5rem;
      --stats-item-gap: 2.5rem;
      --stats-percentage-size: 12.75rem;
      --stats-copy-size: 2.5rem;

      --author-fancy-gap: 1.5rem;
      --author-fancy-img-size: 6.875rem;
      --author-fance-img-border: 0.5rem;

      --author-simple-gap: 2.5rem;
      --author-simple-img-size: 8rem;

      --text-block-gap: 3rem;

      --media-landscape-underlay-padding: 10rem;
      --media-landscape-gap: 5rem;
      --media-landscape-content-padding: 10rem;

      --media-logo-padding: 2.5rem;
      --media-logo-width: 13.5rem;

      --tile-gap: 4rem;
      --tile-inner-gap: 1.5rem;
      --tile-inner-paddding: 3rem;

      --input-icon-gap: 1.25rem;
      --input-border-width: 0.25rem;
      --input-font-size: 1.5rem;
      --input-padding: 1.5rem;
      --input-upload-gap: 1.25rem;
      --input-upload-padding: 9rem;

      --carousel-slide-spacing: 3.5rem;
      --carousel-button-spacing: 75rem;
      --carousel-controls-width: 60rem;
      --carousel-controls-margin: 8rem;

      --people-carousel-width: 35rem;
      --people-carousel-ratio: 2/3;
      --people-carousel-padding: 4rem;
      --people-carousel-v-gap: 1.5rem;
      --people-carousel-name-gap: 1.5rem;
      --pictures-carousel-height: 30rem;

      --testimonial-width: 60rem;
      --testimonial-no-logo-gap: 2.5rem;
      --testimonial-no-logo-icon-height: 9.375rem;
      --testimonial-no-logo-quote-margin: 2rem;
      --testimonial-no-logo-font-size: 2.25rem;

      --testimonial-logo-gap: 4rem;
      --testimonial-logo-logo-height: 4.5rem;
      --testimonial-logo-font-size: 1.75rem;
    }
  }
}

html,
body {
  padding: 0;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;

  .overflow-hidden {
    overflow: hidden;
  }
}

main {
  font-family: var(--inter-font);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figure {
  margin: 0;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.codecapi::before {
  content: '[';
}

.codecapi::after {
  content: ']';
}
